<!--
 * @Author: lbh
 * @Date: 2023-08-11 10:48:03
 * @LastEditors: lbh
 * @LastEditTime: 2023-08-11 11:42:42
 * @Description: file content
-->
<template>
  <div class="edit-contact-module-box">
    <div class="cell-b">
      <selfCell
        title="標題"
        :column="true"
      >
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
        />
      </selfCell>
      <selfCell
        title="標題"
        :column="true"
      >
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}desc`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}desc`)"
          type="textarea"
          rows="5"
        />
      </selfCell>

      <selfCell title="背景顏色">
        <el-color-picker
          v-model="configs.bgColor"
          @change="setValue('bgColor')"
        ></el-color-picker>
      </selfCell>

      <!-- 地圖 -->
      <selfCell
        title="地圖"
        :column="true"
      >
        <el-input
          v-model="configs.contact.address.mapX"
          placeholder="請輸入經度"
          @input="setValue(`contact`)"
        />
        <el-input
          v-model="configs.contact.address.mapY"
          placeholder="請輸入緯度"
          @input="setValue(`contact`)"
        />
        <el-input
          v-model="configs.contact.address.mapY"
          placeholder="請輸入地址"
          @input="setValue(`contact`)"
        />
      </selfCell>
    </div>

    <div class="cell-b">
      <h4>聯繫方式</h4>
      <selfCell
        title="標題"
        :column="true"
      >
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs.contact[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`contact`)"
        />
      </selfCell>
      <div
        v-for="(uItem,uIndex) in configs.contact.types"
        :key="uIndex"
        class="cell-b"
      >
        <el-button-group class="btn-group">
          <el-button
            type="primary"
            icon="el-icon-upload2"
            :disabled="uIndex == 0"
            size="mini"
            @click="itemCheck(uIndex,uIndex-1)"
          ></el-button>
          <el-button
            type="success"
            icon="el-icon-download"
            :disabled="uIndex == configs.contact.types.length-1"
            size="mini"
            @click="itemCheck(uIndex,uIndex+1)"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            :disabled="configs.contact.types.length == 1"
            @click="itemDel(uIndex)"
          ></el-button>
          <el-button
            type="warning"
            icon="el-icon-plus"
            size="mini"
            :disabled="configs.contact.types.length == 5"
            @click="itemAdd(uIndex)"
          ></el-button>
        </el-button-group>
        <selfCell title="圖標">
          <selfUpload
            v-model="uItem.logo"
            type="default"
            @change="setValue('contact')"
          />
        </selfCell>
        <selfCell title="地址">
          <el-input
            v-model="uItem.address"
            placeholder="請輸入跳轉地址"
            @change="setValue('contact')"
          />
        </selfCell>
      </div>

    </div>

    <div class="cell-b">
      <h4>描述</h4>
      <div
        v-for="(laItem,lIndex) in configs.contact.labels"
        :key="lIndex"
        class="cell-b"
      >
        <el-button-group class="btn-group">
          <el-button
            type="primary"
            icon="el-icon-upload2"
            :disabled="lIndex == 0"
            size="mini"
            @click="itemCheck(lIndex,lIndex-1)"
          ></el-button>
          <el-button
            type="success"
            icon="el-icon-download"
            :disabled="lIndex == configs.contact.labels.length-1"
            size="mini"
            @click="itemCheck(lIndex,lIndex+1)"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            :disabled="configs.contact.labels.length == 1"
            @click="itemDel(lIndex)"
          ></el-button>
          <el-button
            type="warning"
            icon="el-icon-plus"
            size="mini"
            :disabled="configs.contact.labels.length == 5"
            @click="itemAdd(lIndex)"
          ></el-button>
        </el-button-group>
        <selfCell
          title="label"
          :column="true"
        >
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="laItem[`${lItem.code=='HK'?'':lItem.code}label`]"
            :placeholder="`請輸入-${lItem.name}`"
            @input="setValue(`contact`)"
          />
        </selfCell>
        <selfCell
          title="value"
          :column="true"
        >
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="laItem[`${lItem.code=='HK'?'':lItem.code}value`]"
            :placeholder="`請輸入-${lItem.name}`"
            @input="setValue(`contact`)"
          />
        </selfCell>
      </div>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
import editModuleMixin from "../../mixins/editModuleMixin"
export default {
  name: "edit-contact-module",
  mixins: [langEditMixin, editModuleMixin],
  methods: {
    // 切換順序
    itemCheck (index, newIndex) {
      let contact = this.configs.contact
      let items = contact.types;
      let _ = items.splice(index, 1);
      items.splice(newIndex, 0, _[0])
      this.$emit('setValue', { key: 'contact', value: contact });
    },
    // 刪除
    itemDel (index) {
      let contact = this.configs.contact
      let items = contact.types;
      items.splice(index, 1);
      this.$emit('setValue', { key: 'contact', value: contact });
    },
    // 新增
    itemAdd (index) {
      let contact = this.configs.contact
      let items = contact.types;
      items.splice(index + 1, 0, this.$util.object.clone(items[index]))
      this.$emit('setValue', { key: 'contact', value: contact });
    }
  }
}
</script>

<style lang="less" scoped>
</style>